import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import StoryblokTemplate from "~templates/storyblok.jsx";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @component
 * Case Study graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 */
const StoryblokProductListing = ({ data, location }) => (
  <StoryblokTemplate
    css={css`
      padding-top: var(--header-height-touch);

      ${breakpoint(`large-tablet`)} {
        padding-top: var(--header-height-desktop);
      }
    `}
    data={data}
    location={location}
  />
);

export default StoryblokProductListing;

export const query = graphql`
  query ProductListing($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id

      full_slug
      name
      content
    }
  }
`;
